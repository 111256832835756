<template>
    <c-panel v-if="order?.data && order.data.cart.length > 0">
        <c-grid class="spacing-xl-all">
            <c-grid-item class="width-100-sm width-50-md width-55-lg width-65-xl">
                <div class="mb-5 pb-5" v-for="(item, index) in order.data.cart" :key="index">
                    <c-grid>
                        <c-grid-item class="width-40-all width-100-lg">
                            <c-card>
                                <c-image
                                    :image="item.image"
                                    v-if="item.image && product(item)?.category == 'Custom Print'"
                                    :zoom="true"
                                />
                            </c-card>
                            <c-card v-if="product(item)?.category == 'Gallery Print'">
                                <c-image :image="product(item)?.images[0]" :zoom="true" />
                            </c-card>
                        </c-grid-item>
                        <c-grid-item class="width-grow-all">
                            <h2>{{ product(item)?.name }}</h2>
                            <p class="p-0 m-0" v-if="item.size">
                                <span class="text-bold">Size: </span>
                                <span>{{ item.size.width }}&times;{{ item.size.height }}</span>
                            </p>
                            <p class="p-0 m-0" v-for="(option, selection) in item.selections" :key="selection">
                                <span class="text-bold">{{ selection }}: </span>
                                <span>{{ option }}</span>
                            </p>
                            <c-grid class="f-align-end">
                                <c-grid-item>
                                    <c-numberbox class="mt-3" v-model="item.quantity" />
                                </c-grid-item>
                                <c-grid-item>
                                    <c-button-group>
                                        <!-- TODO: Add the ability to go back and edit an item -->
                                        <!-- <c-button @click="orderController.removeItem(item)">
                                            <i class="fas fa-pen"></i>
                                        </c-button> -->
                                        <c-button @click="orderController.removeItem(item)" class="red">
                                            <i class="fas fa-trash"></i>
                                        </c-button>
                                    </c-button-group>
                                </c-grid-item>
                            </c-grid>
                        </c-grid-item>
                        <c-grid-item class="width-shrink-all">
                            <h2>${{ item.price.toFixed(0) }}</h2>
                        </c-grid-item>
                    </c-grid>
                </div>
            </c-grid-item>
            <c-grid-item class="width-100-sm width-50-md width-45-lg width-35-xl">
                <c-box class="p-4">
                    <h1 class="mb-5">Summary</h1>
                    <c-grid class="widths-100-all spacing-lg-all">
                        <c-grid-item>
                            <c-order-invoice :orderId="order.id" />
                        </c-grid-item>
                        <c-grid-item>
                            <c-grid class="widths-100-all spacing-xs-all">
                                <c-grid-item class="flex f-justify-stretch">
                                    <c-button class="large light" @click="$router.push('/')">
                                        Continue Shopping
                                    </c-button>
                                </c-grid-item>
                                <c-grid-item>
                                    <c-button
                                        class="large"
                                        @click="$router.push('/checkout' + (orderId ? '/' + orderId : ''))"
                                        >Checkout</c-button
                                    >
                                </c-grid-item>
                                <!--
                                <c-grid-item>
                                    <c-button class="large" @click="orderController.estimateParcel()">Pack</c-button>
                                </c-grid-item>
                                <c-grid-item>
                                    <c-button
                                        class="large"
                                        @click="$router.push('/order' + (orderId ? '/' + orderId : ''))"
                                        >Order Page</c-button
                                    >
                                </c-grid-item>-->
                            </c-grid>
                        </c-grid-item>
                    </c-grid>
                </c-box>
            </c-grid-item>
        </c-grid>
    </c-panel>
    <c-panel v-else>
        <c-grid class="widths-100-all">
            <c-grid-item class="text-center">
                <h1 class="mt-5">Your Cart is Empty!</h1>
            </c-grid-item>
            <c-grid-item class="flex f-justify-center">
                <c-button @click="$router.push('/')">Shop Now</c-button>
            </c-grid-item>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { inject, reactive } from "vue";
import { Database } from "vuebase";
import { CartItem, Product } from "../main";
import OrderController from "@/controllers/order-controller";
import { useRoute } from "vue-router";

export default {
    name: "Cart",
    setup() {
        const route = useRoute();
        const orderId = (route.params.id as string) || undefined;
        const database = inject(Database.InjectionKey);
        const products = database?.collection<Product>("products").documents();
        const orderController = reactive(new OrderController(orderId));

        function product(item: CartItem): Product | undefined {
            return products?.find((product) => product.id == item.productId)?.data;
        }

        return {
            orderId,
            order: orderController.order,
            orderController,
            product,
        };
    },
};
</script>
